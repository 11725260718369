const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  stage_list: [],
  prerequisiteData: [],
};

const taskMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASK_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        breakDownCategoryData: [],
      };
    case "CREATE_TASK":
      return { ...state };
    case "UPDATE_TASK":
      return { ...state };
    case "GET_STAGE_LIST":
      return {
        ...state,
        stage_list: action?.stage_list,
      };
    case "GET_STAGE_DATA_BY_PHASE":
      return {
        ...state,
        stageData: action.stageData,
      };
    case "GET_PREREQUISITE_TASK_LIST":
      return {
        ...state,
        prerequisiteData: action.data,
      };

    default:
      return { ...state };
  }
};

export default taskMasterReducer;
